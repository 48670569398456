@use '@angular/material' as mat;
@import '_variables.scss';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Tutorials
// https://blog.thoughtram.io/angular/2017/05/23/custom-themes-with-angular-material.html
// https://github.com/angular/flex-layout/wiki/Declarative-API-Overview

// Base
// https://material.angular.io/guide/theming

$app-primary: mat.define-palette($theme-palette-primary);
$app-accent: mat.define-palette($theme-palette-accent);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette($theme-palette-warn);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);

@include mat.all-component-themes($app-theme);

// Type
// https://material.angular.io/guide/typography

$app-typography: mat.define-typography-config(
  $font-family: $theme-body-font-family,
  $headline-4: mat.define-typography-level(16px, 48px, 600),
  $headline-5: mat.define-typography-level(36px, 48px, 300),
  $headline-6: mat.define-typography-level(24px, 40px, 400),
  $subtitle-1: mat.define-typography-level(14px, 16px, 400),
  $body-1: mat.define-typography-level(14px, 24px, 300),
  $body-2: mat.define-typography-level(14px, 24px, 400),
  $subtitle-2: mat.define-typography-level(14px, 24px, 400),
  $button: mat.define-typography-level(15px, 24px, 300),
  /* TODO(mdc-migration): No longer supported. Use `body-1` instead. $input: mat.define-typography-level(14px, 1.2, 400), */
);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($app-typography);

// Override the typography in the core CSS.
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($app-typography);`
@include mat.all-component-typographies($app-typography);
@include mat.core();
// @include mat-form-field-theme($app-theme); // Form-Field theme
// @include mat-input-theme($app-theme);      // Input Theme

body {
  font-family: $theme-body-font-family;
  background-color: $theme-body-bkg;
  color: $theme-body-color;
  font-size: $theme-body-font-size;
}

a {
  text-decoration: none;
  font-weight: 500;
  color: $theme-link-color;
}

a:hover {
  color: $theme-link-hover-color;
  cursor: pointer;
}

h1,
h2,
h3,
h4 {
  font-family: $theme-headings-font-family;
  font-weight: 500;
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 24px;
  width: 100%;
}

h3 {
  font-size: 18px;
  &.label-input-header {
    .mat-form-label,
    .mat-form-input.mat-form-input-label {
      font-size: 18px;
      font-family: var(--nvz-font-family-headings);
    }
  }
}

h4 {
  font-size: 14px;
}

section {
  padding: 20px;
}

.mat-button-toggle {
  font-family: $theme-button-font-family;
}

// Buttons
// button,
// a {
//     &.mat-mdc-button,
//     &.mat-mdc-unelevated-button,
//     &.mat-mdc-icon-button,
//     &.mat-mdc-outlined-button {
//         border-radius: 2px;
//         font-size: 15px;
//     }
// }

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
// mat-card.mat-mdc-card {
//     border-radius: 4px;
// }

form mat-error {
  margin-top: -5px;
  font-size: 0.8em;
  font-weight: 600;
}

// Layout

@media screen and (max-width: 599px) {
  .text-left-xs {
    text-align: left !important;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.block {
  display: block !important;
}

.text-primary {
  color: mat.get-color-from-palette($app-primary);
}

.text-accent {
  color: mat.get-color-from-palette($app-accent);
}

.text-danger {
  color: $theme-danger;
}

.text-warning {
  color: $theme-warning;
}

.text-success {
  color: $theme-success;
}

.text-muted {
  color: $theme-muted;
}

.text-gray {
  color: $theme-gray;
}

.text-strikeout {
  color: $theme-strikeout-color;
  text-decoration: line-through;
}

.empty-container {
  font-size: 1.2em;
  color: $theme-container-empty-color;
  padding: 20px;
  text-align: center;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.container-dark {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 268px;
  background: $theme-container-dark-bkg;
  z-index: 0;
  color: $theme-container-dark-color;
  @media screen and (max-width: 599px) {
    height: 100%;
  }
}

.container-dark-header {
  padding: 0 20px 20px;
}

.container-main {
  margin: 0 auto;
  z-index: 1;
  position: relative;
  padding: 0 8px 0;
  top: 170px;
  @media screen and (max-width: 599px) {
    top: 60px;
  }
}

.logo {
  width: 200px;
  height: 60px;
  @media screen and (max-width: 599px) {
    height: 40px;
    width: 70px;
    margin: 5px 10px 0 15px;
    background-image: $theme-logo-mobile;
  }
  background-image: $theme-logo;
  margin: 12px 0 0 50px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}

.panel {
  background-color: white;
  border-radius: $theme-panel-border-radius;
  box-shadow: 0 2px 4px 0 rgba(153, 171, 189, 0.2);
}

.panel-header {
  font-weight: bold;
  padding: 0 24px;
  margin: 0;

  @media screen and (max-width: 599px) {
    padding: 0 16px;
  }
}

.panel-vh {
  height: calc(100vh - 180px);
  overflow: auto;
  max-height: none;
  position: relative;

  .panel-footer-spacer {
    height: 70px;
  }
  @media screen and (max-width: 599px) {
    // height: calc(100vh - 196px);
    height: calc(100vh - 224px);
    &.panel-vh-mobile-auto {
      height: auto;
      margin-bottom: 50px;
    }
    &.panel-sidebar {
      height: auto;
      gap: 0 !important;
      .panel-body {
        padding-bottom: 0;
      }
    }
  }
}

.panel-body {
  padding: 16px;
  @media screen and (max-width: 599px) {
    padding: 10px;

    .panel-header {
      margin: 0 -10px;
    }
  }
}

.panel-body-sticky {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  overflow: auto;
}

.panel-body-sticky-container {
  position: relative;
  padding-bottom: 40px;
}

@media screen and (max-width: 599px) {
  .panel-body-sticky-responsive {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    min-height: 50px;
  }
}

.panel-body-head {
  padding-top: 0;
}

.panel-title {
  padding: 24px 24px 0 24px;
  h1,
  h2,
  h3 {
    margin: 0;
  }
}

.panel-sidebar {
  margin-right: 8px;
  .panel {
    overflow: auto;
  }
  @media screen and (max-width: 599px) {
    margin-right: 0;
    margin-bottom: 12px;
    .panel-body {
      overflow: auto;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 959px) {
    .panel-body {
      padding: 24px 10px;
    }
  }
  h3 {
    margin: 10px 0 0 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

.panel-footer {
  padding: 8px;
}

.panel-footer-sticky {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid $theme-separator-border;
  background-color: white;
}

.panel-footer-sticky-mobile {
  @media screen and (max-width: 599px) {
    position: fixed;
  }
}

.coin {
  width: 65px;
  height: 65px;
  cursor: pointer;
  border-radius: 40px;
  box-shadow: 0 2px 1px 0 rgba(142, 148, 155, 0.7);
  // border: $theme-coin-border;
  margin: 5px;
  background: $theme-coin-bkg;
  transition:
    width 0.2s ease-out,
    height 0.2s ease-out;

  &.coin-sm {
    width: 50px;
    height: 50px;
  }

  mat-icon {
    font-size: 2.2em;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      font-size: 35px;
    }

    &.menu-chevron {
      &:before {
        font-size: 22px;
      }
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-menu-body {
  padding: 0 10px;
  min-width: 230px;
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-menu-header {
  padding-left: 10px;
  h3 {
    margin: 0;
    font-weight: 400;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-menu-actions {
  margin: 0 10px 10px;
  padding-top: 10px;
  border-top: $theme-menu-actions-border;
}

.coin-label {
  font-size: 0.8em;
  font-weight: 600;
  max-height: 50px;
  pointer-events: none;
  @media screen and (max-width: 599px) {
    font-size: 0.7em;
  }
}

.coin-info {
  position: absolute;
  background: $theme-coin-info-bkg;
  font-size: 0.8em;
  color: $theme-coin-info-color;
  border-radius: 4px;
  padding: 0 3px;
  left: 50%;
  margin-left: -16px;
  top: -13px;
}

.coin-container {
  margin: 5px 10px;
  text-align: center;
  @media screen and (min-width: 600px) and (max-width: 959px) {
    margin: 0px 10px;
  }
}

.mat-mdc-form-field {
  padding: 10px 0;
}

.mat-form-field {
  padding: 14px 0;
}

.mat-form-label {
  display: block;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 4px;
  // height: 1.5em;
}

mat-option.mat-mdc-option .mdc-list-item__primary-text {
  font-weight: 400;
}

.mat-form-label-optional {
  color: $theme-label-optional-color;
  font-size: 12px;
  margin-bottom: 5px;
}

.mat-form-assistive {
  color: $theme-label-assistive-color;
  font-size: 12px;
  margin-top: 2px;
}

.mat-form-input {
  padding: 0 8px;
  box-sizing: border-box;
  height: calc(var(--nvz-body-font-size) * 2.25);
  border-radius: $theme-form-input-border-radius;
  border: $theme-form-input-border;
  background: $theme-form-input-bkg;
}

.mat-form-input.mat-form-input-label {
  border: 0px;
  padding: 0;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: var(--nvz-font-family-body);
  max-height: 1.5em;
  width: calc(100% - 12px);
}

.panel-body-title {
  margin-top: 0;
  margin-bottom: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
mat-mdc-select.mat-form-input,
select.mat-form-input {
  height: calc(var(--nvz-body-font-size) * 2.25);
  padding: 0;
  font-size: 13px !important;
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  & .mat-mdc-select-trigger {
    height: 100%;
    padding: 0 8px;
    font-size: 13px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  & .mat-mdc-select-value {
    padding-top: 8px;
    font-size: 13px !important;
  }
}

app-year-picker:focus-within {
  outline: 1px solid #ff5f2b;
}

.frequency-input.mat-mdc-select:focus {
  outline: 1px solid #ff5f2b !important;
}
.mat-form-row {
  margin-bottom: 12px;
}

.full-width {
  width: 100%;
}

@media screen and (max-width: 599px) {
  .full-width-xs {
    width: 100%;
  }
}

.button-cancel {
  display: flex;
  position: relative;
  top: 10px;
  align-self: flex-start;
}

.hide {
  display: none;
}

.drop-zone {
  border: $theme-drop-zone-border;
  width: 70px;
  height: 70px;
  border-radius: 40px;
  transition: all 0.2s ease-out;

  &.drop-zone-area {
    width: 100%;
    border-radius: 10px;
  }
  mat-icon {
    font-size: 1.5em;
    color: $theme-drop-zone-color;
  }

  &.drop-zone-active {
    border-color: $theme-drop-zone-active-border;
    background-color: $theme-drop-zone-active-bkg;
    color: $theme-drop-zone-active-color;
  }

  &.drop-zone-disabled {
    opacity: 0.4;
    background-color: $theme-drop-zone-disabled-bkg;
  }
}

.drop-zone-container-mobile {
  padding: 0 10px;
}

.drop-zone-sticky {
  position: sticky;
  top: -24px;
  background: white;
  z-index: 100;
  padding-top: 12px;
  box-shadow: 0 6px 7px white;
  padding-bottom: 2px;
  margin-bottom: 16px;

  @media screen and (max-width: 599px) {
    top: -12px;
  }
}

.panel-body-separator {
  border-right: 1px solid $theme-separator-border;
  @media screen and (max-width: 599px) {
    border-right: 0;
    border-bottom: 1px solid $theme-separator-border;
  }
}

.mat-mdc-menu-content {
  position: relative;
}

// .mat-form-button {
//     margin-left:10px;
//     position:relative;
//     top: 10px;
// }

.mat-form-button.mat-mdc-icon-button {
  margin-top: 18px;
  margin-left: 5px;
  font-size: var(--nvz-body-font-size);
}

.mat-mdc-menu-panel {
  // need this to show the arrow
  overflow: auto !important;
}

.mat-mdc-menu-content:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  display: inline-block;
  border-width: 7px;
  z-index: 101;
  border-color: transparent transparent white transparent;
  position: absolute;
  top: -14px;
  right: 20px;
}

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-menu-top .mat-mdc-menu-content {
  text-align: center;
  &:before {
    border-color: white transparent transparent transparent;
    top: 34px;
    right: auto;
    left: 25px;
  }
}

.mat-mdc-menu-content:not(:empty) {
  padding: 0 !important;
}

.mat-mdc-menu-panel {
  max-height: 525px !important;
  max-width: 350px !important;
}

.goal-menu {
  min-width: 350px !important;
}

.account-menu {
  display: inline-block;
  float: right;
  color: $theme-account-menu-color;
  margin: 10px 20px;
  @media screen and (max-width: 599px) {
    margin: 10px 0;
  }
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-right-10 {
  margin-right: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-checked,
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  background: var(--nvz-button-bkg);
  color: var(--nvz-button-color);
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
  border-bottom: 0 !important;
}

.mat-mdc-tooltip {
  font-size: 15px;
}

.info-panel {
  padding: 16px;
  background-color: $theme-panel-info-bkg;
  border-radius: 2px;
  color: $theme-panel-info-color;
  mat-icon {
    font-size: 1.5em;
    margin-right: 10px;
  }
}

.total {
  margin-bottom: 10px;
  .total-number {
    font-size: 20px;
    font-weight: 300;
  }
  .total-text {
    font-size: 14px;
    font-weight: 300;
  }
  .total-descr {
    color: $theme-muted;
    font-weight: 500;
    font-size: 16px;
  }
}

a mat-icon {
  font-size: 1.3em;
}

.action-list-selection {
  .action-list-item {
    padding: 10px 10px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    &:last-child {
      border-bottom: 0px;
      padding-bottom: 16px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding-top: 16px;
    }
  }
  .action-list-item:hover {
    background: $theme-action-list-item-hover-bkg;
  }
  .action-list-item-active {
    color: $theme-action-list-item-active-color;
  }
}

.action-list-item-button {
  padding: 8px 0 0;
  border: 0;
  background: 0;
  position: absolute;
  right: 0;
  color: $theme-action-list-item-button-color;
}

.action-list-item {
  padding: 16px 0;
  position: relative;
  border-bottom: 1px solid $theme-action-list-item-border;
  &:last-child {
    border-bottom: 0px;
    padding-bottom: 0;
  }
  &:first-child {
    padding-top: 0;
  }
}

small.text-muted {
  font-weight: 600;
}

.advisor-menu.mat-mdc-menu-panel {
  max-width: fit-content;

  img {
    float: left;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  h4 {
    margin-top: 10px;
  }
}

.help-button {
  font-weight: bold;
  border-radius: 11px;
  width: 18px;
  display: inline-block;
  text-align: center;
  font-size: 0.9em;
  background: $theme-help-bkg;
  color: $theme-help-color;
  cursor: pointer;
}

.help-panel.mat-mdc-menu-panel {
  max-width: 550px;
  overflow: auto !important;
  max-height: 300px;
}

.link-chevron {
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;

  @media screen and (max-width: 599px) {
    justify-content: space-between;
    width: 100%;
  }
}

.link-more {
  position: relative;
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    display: inline-block;
    border-width: 5px;
    z-index: 101;
    border-color: transparent transparent transparent
      mat.get-color-from-palette($app-primary);
    position: absolute;
    top: 4px;
    right: -15px;
  }
  &.link-more-active:after {
    border-color: mat.get-color-from-palette($app-primary) transparent
      transparent transparent;
    top: 7px;
  }
}

@media screen and (max-width: 599px) {
  .grid-list-container {
    overflow: auto;

    .mat-grid-list {
      width: 500px;
    }
  }

  .container-main {
    padding: 0;
  }
  .panel {
    border-radius: 0;
  }
  .pan-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .drawer {
    height: 0;
    overflow: hidden;
    opacity: 0;
  }

  .drawer-container-open {
    &.main-pan {
    height: calc(100% - 340px);
    }
    &.drawer {
      height: 340px;
      opacity: 1;
      overflow: auto;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
  div.mat-form-field-action {
    padding: 0 !important;
  }
  .text-left-sm {
    text-align: left !important;
  }
}

hr {
  background-color: none;
  border: 0;
  // border-bottom: 1px solid darken($color: $theme-body-bkg, $amount: 5%);
  border-bottom: 1px solid $theme-separator-border;
}

.mat-form-row-suggest {
  // background-color: darken($theme-body-bkg, 5%);
  background-color: $theme-body-bkg;
  padding: 10px 0;
  border-radius: 5px;
  .menu-chevron {
    height: 15px;
    line-height: 0;
  }

  > div:last-child {
    padding: 0 10px;
  }
}

.list-menu {
  max-height: 200px;
  padding: 10px;
  overflow: auto;
  width: 250px;
  font-size: 0.9em;
  .text-success,
  .text-danger {
    white-space: nowrap;
  }
  > div {
    padding: 5px 0;
    // border-bottom: 1px solid darken($color: $theme-body-bkg, $amount: 5%);
    border-bottom: 1px solid $theme-body-bkg;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.mat-form-label-clickable {
  min-height: 1.5em;
  min-width: 50%;
  cursor: pointer;
  text-decoration: underline;
}

.vendor-info,
.vendor {
  display: none;
}

.share-panel {
  // background-color: darken($color: $theme-body-bkg, $amount: 5%);
  background-color: $theme-body-bkg;
  border-radius: 10px;
  .share-panel-header {
    padding: 10px 20px;
    border-bottom: 1px solid $theme-body-bkg;
  }
  .share-panel-body {
    padding: 0 20px;
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        border-bottom: 1px solid $theme-body-bkg;
        font-weight: bold;
        padding: 10px 0;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

div.mat-dialog-actions {
  padding: 24px 0;
}

.dialog-dismiss {
  position: absolute !important;
  right: 8px;
  mat-icon:before {
    font-size: 16px;
  }
}

// hr {
//     border-color: black;
// }

// h2:after {
//     content: "";
//     display: block;
//     height: 1px;
//     background: black;
//     width: 100%;
//     margin-top: 7px;
// }

.mat-mdc-form-field-error {
  margin-top: 5px;
  font-size: 10px;
  font-weight: 600;
}

.scenarios-list-selection{
  max-height: 380px;
  overflow-y: auto;
  
  &.scenarios-list {
    padding: 15px 0px;
    cursor: pointer;
  }
  .scenarios-list-item {
    padding: 8px 6px;
    border: 1px solid #ABAEFD;
    border-radius: 4px;
    background: transparent;
  }
  .scenarios-list-item:hover {
    background: $theme-action-list-item-hover-bkg;
  }
  .scenarios-list-item-active {
    background: #ABAEFD;  //$theme-action-list-item-active-color;
  }
  .scenarios-list-item-compared {
    background: rgba(171, 174, 253, 0.30);
  }
}

.underlined {
  text-decoration: underline;
  cursor: pointer;
}