@use '@angular/material' as mat;
@import '_ci';

$theme-palette-primary: $envizage-palette-primary;
$theme-palette-accent: $envizage-palette-blood-orange;
$theme-palette-warn: $envizage-palette-danger;

:root {
  --nvz-body-font-size: 14px;
  --nvz-body-bkg: #{mat.get-color-from-palette($envizage-palette-cream, 500)};
  --nvz-body-color: #{mat.get-color-from-palette($envizage-palette-black)};

  --nvz-container-dark-bkg: #{mat.get-color-from-palette($mat-slate, 500)};
  --nvz-container-dark-color: white;
  --nvz-container-empty-color: #{mat.get-color-from-palette(
      $envizage-palette-black,
      200
    )};

  --nvz-logo-url: none;
  --nvz-logo-mobile-url: none;
  --nvz-logo-auth-url: none;

  --nvz-font-family-body: 'Poppins', sans-serif;
  --nvz-font-family-headings: 'Poppins', sans-serif;
  --nvz-font-family-button: var(--nvz-font-family-body);

  --nvz-color-primary: #{mat.get-color-from-palette($theme-palette-primary, 500)};
  --nvz-color-primary-contrast: #{mat.get-color-from-palette(
      $envizage-palette-black
    )};
  --nvz-color-accent: #{mat.get-color-from-palette($theme-palette-accent)};
  --nvz-color-accent-contrast: #{mat.get-color-from-palette(
      $envizage-palette-black
    )};
  --nvz-color-warning: #{mat.get-color-from-palette($envizage-palette-warning)};
  --nvz-color-warning-contrast: #{mat.get-color-from-palette(
      $envizage-palette-black
    )};
  --nvz-color-danger: #{mat.get-color-from-palette($envizage-palette-danger)};
  --nvz-color-danger-contrast: #fff;
  --nvz-color-success: #{mat.get-color-from-palette($envizage-palette-success)};
  --nvz-color-success-contrast: #{mat.get-color-from-palette(
      $envizage-palette-black
    )};
  --nvz-color-info: #{mat.get-color-from-palette($envizage-palette-sky)};
  --nvz-color-muted: #{mat.get-color-from-palette($envizage-palette-black, 200)};
  --nvz-color-gray-light: #{mat.get-color-from-palette(
      $envizage-palette-black,
      50
    )};
  --nvz-color-gray: #{mat.get-color-from-palette($envizage-palette-black, 200)};
  --nvz-color-gray-dark: #{mat.get-color-from-palette(
      $envizage-palette-black,
      400
    )};
  --nvz-color-strikeout: #{mat.get-color-from-palette(
      $envizage-palette-black,
      300
    )};
  --nvz-color-label-optional: #{mat.get-color-from-palette(
      $envizage-palette-black,
      200
    )};
  --nvz-color-label-assistive: #{mat.get-color-from-palette(
      $envizage-palette-black,
      200
    )};

  --nvz-link-color: #{mat.get-color-from-palette(
      $envizage-palette-sky-blue,
      700
    )};
  --nvz-link-color-hover: #{mat.get-color-from-palette(
      $envizage-palette-sky-blue,
      900
    )};

  --nvz-button-color: black;
  --nvz-button-bkg: var(--nvz-color-primary);
  --nvz-button-border-radius: 2px;
  --nvz-button-font-size: 13px;
  --nvz-button-font-weight: 500;

  --nvz-panel-border-radius: 0px;

  --nvz-help-color: white;
  --nvz-help-bkg: var(--nvz-color-info);

  --nvz-form-input-border: var(--nvz-color-gray);
  --nvz-form-input-border-radius: 2px;
  --nvz-form-input-bkg: white;

  --nvz-coin-border: var(--nvz-color-gray);
  --nvz-coin-bkg: var(--nvz-body-bkg);
  --nvz-coin-info-bkg: var(--nvz-color-accent);
  --nvz-coin-info-color: white;
  --nvz-coin-red-bkg: var(
    --nvz-color-danger
  ); // #{mat.get-color-from-palette($envizage-palette-danger, 300)};
  --nvz-coin-amber-bkg: var(
    --nvz-color-warning
  ); // #{mat.get-color-from-palette($envizage-palette-warning, 200)};
  --nvz-coin-green-bkg: var(
    --nvz-color-success
  ); // #{mat.get-color-from-palette($envizage-palette-success, 200)};
  --nvz-coin-label-bkg: var(
    --nvz-link-color
  ); // #{mat.get-color-from-palette($envizage-palette-sky-blue)};
  --nvz-coin-label-color: white;
  --nvz-coin-container-bkg: #{mat.get-color-from-palette(
      $envizage-palette-dark-teal
    )};

  --nvz-menu-action-border: #{mat.get-color-from-palette($envizage-palette-gray)};
  --nvz-separator-border: var(--nvz-color-gray-light);

  --nvz-drop-zone-border: var(--nvz-color-muted);
  --nvz-drop-zone-color: var(--nvz-color-muted);
  --nvz-drop-zone-active-bkg: rgba(0, 214, 138, 0.2);
  --nvz-drop-zone-active-color: var(--nvz-color-success);
  --nvz-drop-zone-active-border: var(--nvz-color-success);
  --nvz-drop-zone-disabled-bkg: #{mat.get-color-from-palette(
      $envizage-palette-gray,
      100
    )};

  --nvz-panel-info-bkg: var(
    --nvz-color-info
  ); // #{mat.get-color-from-palette($envizage-palette-purple, 300)};
  --nvz-panel-info-color: var(
    --nvz-body-color
  ); // #{mat.get-color-from-palette($envizage-palette-night-blue)};

  --nvz-action-list-item-border: #{mat.get-color-from-palette(
      $envizage-palette-gray-light
    )};
  --nvz-action-list-item-hover-bkg: #{mat.get-color-from-palette(
      $envizage-palette-gray-light
    )};
  --nvz-action-list-item-active-color: var(--nvz-link-color);
  --nvz-action-list-item-button-color: var(--nvz-color-danger);

  --nvz-wizard-item-label-color: white;
  --nvz-wizard-item-number-color: white;
  --nvz-wizard-item-number-bkg: #{mat.get-color-from-palette(
      $envizage-palette-darkgrey
    )};
  --nvz-wizard-item-active-number-color: white;
  --nvz-wizard-item-active-number-bkg: var(--nvz-color-accent);
  // #{mat.get-color-from-palette($envizage-palette-green)};
  --nvz-wizard-item-active-label-color: white;

  // #{mat.get-color-from-palette($envizage-palette-cream)};
  --nvz-swimlane-bkg: var(--nvz-color-gray-light);
  //#{mat.get-color-from-palette($envizage-palette-black, 200)};
  --nvz-swimlane-label-color: var(--nvz-color-gray-dark);
  // #{mat.get-color-from-palette($envizage-palette-black)};
  --nvz-swimlane-axis-border: var(--nvz-color-accent);
  // #{mat.get-color-from-palette($envizage-palette-black)};
  --nvz-swimlane-axis-color: var(--nvz-color-accent);

  --nvz-swimlane-drop-area-bkg: transparent;

  //#{mat.get-color-from-palette($envizage-palette-black)};
  --nvz-auth-header-color: var(--nvz-color-accent);

  --nvz-auth-header-bkg: var(--nvz-body-bkg);
  --nvz-auth-img-url: none;
  --nvz-auth-gradient-bkg: black;

  --nvz-account-menu-color: var(--nvz-body-color);

  /** material overwrites **/
  --mdc-typography-body1-font-family: var(--nvz-font-family-body);
  --mdc-typography-button-font-family: var(--nvz-font-family-button);
  --mdc-typography-button-font-size: var(--nvz-button-font-size);
  --mdc-typography-button-font-weight: var(--nvz-button-font-weight);
  --mdc-filled-button-label-text-color: var(--nvz-button-color);
  --mdc-filled-button-container-color: var(--nvz-button-bkg);
  --mdc-filled-button-container-shape: var(--nvz-button-border-radius);
  --mdc-shape-medium: var(--nvz-panel-border-radius); // variable used in popups
  --mdc-switch-selected-handle-color: var(--nvz-button-bkg);
  --mdc-switch-selected-track-color: var(--nvz-button-bkg);
  --mat-menu-item-label-text-weight: 500;
}

// colors
$theme-primary: var(--nvz-color-primary);
$theme-accent: var(--nvz-color-accent);
$theme-warning: var(--nvz-color-warning);
$theme-danger: var(--nvz-color-danger);
$theme-success: var(--nvz-color-success);
$theme-primary-contrast: var(--nvz-color-primary-contrast);
$theme-accent-contrast: var(--nvz-color-accent-contrast);
$theme-warning-contrast: var(--nvz-color-warning-contrast);
$theme-danger-contrast: var(--nvz-color-danger-contrast);
$theme-success-contrast: var(--nvz-color-success-contrast);
$theme-muted: var(--nvz-color-muted);
$theme-gray: var(--nvz-color-gray);
$theme-gray-dark: var(--nvz-color-gray-dark);

// typography
$theme-body-font-size: var(--nvz-body-font-size);
$theme-body-font-family: var(--nvz-font-family-body);
$theme-headings-font-family: var(--nvz-font-family-headings);
$theme-button-font-family: var(--nvz-font-family-button);

$theme-strikeout-color: var(--nvz-color-strikeout);
$theme-label-optional-color: var(--nvz-color-label-optional);
$theme-label-assistive-color: var(--nvz-color-label-assistive);

// containers
$theme-body-bkg: var(--nvz-body-bkg);
$theme-body-color: var(--nvz-body-color);
$theme-container-dark-bkg: var(--nvz-container-dark-bkg);
$theme-container-dark-color: var(--nvz-container-dark-color);
$theme-container-empty-color: var(--nvz-container-empty-color);

//elements
$theme-logo: var(--nvz-logo-url);
$theme-logo-mobile: var(--nvz-logo-mobile-url);

$theme-coin-border: 1px solid var(--nvz-coin-border);
$theme-coin-bkg: var(--nvz-coin-bkg);
$theme-coin-info-bkg: var(--nvz-coin-info-bkg);
$theme-coin-info-color: var(--nvz-coin-info-color);
$theme-coin-red-bkg: var(--nvz-coin-red-bkg);
$theme-coin-amber-bkg: var(--nvz-coin-amber-bkg);
$theme-coin-green-bkg: var(--nvz-coin-green-bkg);
$theme-coin-year-bkg: var(--nvz-coin-label-bkg);
$theme-coin-label-color: mat.get-color-from-palette($envizage-palette-teal);
$theme-coin-year-color: var(--nvz-coin-label-color);
$theme-coin-container-bkg: var(--nvz-coin-container-bkg);

$theme-menu-actions-border: 1px solid var(--nvz-menu-action-border);

$theme-form-input-border: 1px solid var(--nvz-form-input-border);
$theme-form-input-border-radius: var(--nvz-form-input-border-radius);
$theme-form-input-bkg: var(--nvz-form-input-bkg);

$theme-drop-zone-border: 2px dashed var(--nvz-drop-zone-border);
$theme-drop-zone-color: var(--nvz-drop-zone-color);
$theme-drop-zone-active-border: var(--nvz-drop-zone-active-border);
$theme-drop-zone-active-bkg: var(--nvz-drop-zone-active-bkg);
$theme-drop-zone-active-color: var(--nvz-drop-zone-active-color);
$theme-drop-zone-disabled-bkg: var(--nvz-drop-zone-disabled-bkg);

$theme-separator-border: var(--nvz-separator-border);

$theme-panel-info-bkg: var(--nvz-panel-info-bkg);
$theme-panel-info-color: var(--nvz-panel-info-color);
$theme-panel-border-radius: var(--nvz-panel-border-radius);

$theme-action-list-item-border: var(--nvz-action-list-item-border);
$theme-action-list-item-hover-bkg: var(--nvz-action-list-item-hover-bkg);
$theme-action-list-item-active-color: var(--nvz-action-list-item-active-color);
$theme-action-list-item-button-color: var(--nvz-action-list-item-button-color);

$theme-help-bkg: var(--nvz-help-bkg);
$theme-help-color: var(--nvz-help-color);

$theme-link-color: var(--nvz-link-color);
$theme-link-hover-color: var(--nvz-link-color-hover);

$theme-wizard-item-label-color: var(--nvz-wizard-item-label-color);
$theme-wizard-item-number-color: var(--nvz-wizard-item-number-color);
$theme-wizard-item-number-bkg: var(--nvz-wizard-item-number-bkg);
$theme-wizard-item-active-number-color: var(
  --nvz-wizard-item-active-number-color
);
$theme-wizard-item-active-number-bkg: var(--nvz-wizard-item-active-number-bkg);
$theme-wizard-item-active-label-color: var(
  --nvz-wizard-item-active-label-color
);

$theme-swimlane-bkg: var(--nvz-swimlane-bkg);
$theme-swimlane-label-color: var(--nvz-swimlane-label-color);
$theme-swimlane-axis-border: var(--nvz-swimlane-axis-border);
$theme-swimlane-axis-color: var(--nvz-swimlane-axis-color);
$theme-swimlane-drop-area-bkg: var(--nvz-swimlane-drop-area-bkg);

$theme-auth-header-color: var(--nvz-auth-header-color);
$theme-auth-header-bkg: var(--nvz-auth-header-bkg);
$theme-auth-img: var(--nvz-auth-img-url);
$theme-auth-gradient-bkg: var(--nvz-auth-gradient-bkg);

$theme-account-menu-color: var(--nvz-account-menu-color);

$theme-advisor-img: none;

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
// mat-mdc-select.mat-form-input,
// select.mat-form-input {
//   background: mat.get-color-from-palette($envizage-palette-cream);
// }

a,
button {
  // .mat-mdc-button:not(:disabled),
  &.mat-mdc-raised-button:not(:disabled),
  // .mat-mdc-icon-button:not(:disabled),
  // .mat-mdc-outlined-button:not(:disabled),
  &.mat-mdc-unelevated-button:not(:disabled),
  &.mat-mdc-fab:not(:disabled),
  &.mat-mdc-mini-fab:not(:disabled) {
    background-color: var(--nvz-button-bkg);
    color: var(--nvz-button-color);
  }
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background-color: var(--nvz-button-bkg);
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  background-color: var(--nvz-button-bkg);
}

// .mat-form-input {
//   background-color: mat.get-color-from-palette($envizage-palette-cream);
// }

.panel-body-title,
h3,
h4,
.panel-header h2 {
  color: var(--nvz-color-accent);
}

// .panel-body,
// .panel-header {
//   h2 {
//     color: mat.get-color-from-palette($envizage-palette-teal);
//   }
// }

.logo.auth-logo {
  background-image: var(--nvz-logo-auth-url);
}

$auth-background-size: auto;
