/* generate palette at http://mcg.mbitson.com */
@use '@angular/material' as mat;

$mat-bloodorange: (
  50: #ffece6,
  100: #ffcfbf,
  200: #ffaf95,
  300: #ff8f6b,
  400: #ff774b,
  500: #ff5f2b,
  600: #ff5726,
  700: #ff4d20,
  800: #ff431a,
  900: #ff3210,
  A100: #ffffff,
  A200: #fff9f8,
  A400: #ffccc5,
  A700: #ffb5ac,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-nightblue: (
  50: #e1e4f0,
  100: #b5bcd8,
  200: #8490bf,
  300: #5264a5,
  400: #2d4291,
  500: #08217e,
  600: #071d76,
  700: #06186b,
  800: #041461,
  900: #020b4e,
  A100: #8189ff,
  A200: #4e5aff,
  A400: #1b2aff,
  A700: #0212ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-skyblue: (
  50: #f2f4fa,
  100: #dfe4f2,
  200: #c9d2ea,
  300: #b3c0e2,
  400: #a3b3db,
  500: #93a5d5,
  600: #8b9dd0,
  700: #8093ca,
  800: #768ac4,
  900: #6479ba,
  A100: #ffffff,
  A200: #f7f9ff,
  A400: #c4d2ff,
  A700: #abbfff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-poolblue: (
  50: #f6fdff,
  100: #e8fbff,
  200: #d9f8ff,
  300: #caf5ff,
  400: #bef2ff,
  500: #b3f0ff,
  600: #aceeff,
  700: #a3ecff,
  800: #9ae9ff,
  900: #8be5ff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-success: (
  50: #e2faf2,
  100: #b7f3de,
  200: #87ebc8,
  300: #57e2b2,
  400: #33dca2,
  500: #0fd691,
  600: #0dd189,
  700: #0bcc7e,
  800: #08c674,
  900: #04bc62,
  A100: #e5fff1,
  A200: #b2ffd5,
  A400: #7fffb9,
  A700: #65ffac,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-danger: (
  50: #fbe6e6,
  100: #f5c2c2,
  200: #ee9999,
  300: #e77070,
  400: #e15151,
  500: #dc3232,
  600: #d82d2d,
  700: #d32626,
  800: #ce1f1f,
  900: #c51313,
  A100: #fff5f5,
  A200: #ffc2c2,
  A400: #ff8f8f,
  A700: #ff7575,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-warning: (
  50: #fdf8e7,
  100: #f9edc3,
  200: #f5e29c,
  300: #f1d674,
  400: #eecd56,
  500: #ebc438,
  600: #e9be32,
  700: #e5b62b,
  800: #e2af24,
  900: #dda217,
  A100: #ffffff,
  A200: #fff3db,
  A400: #ffe3a8,
  A700: #ffdb8f,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-info: (
  50: #ebf8fe,
  100: #ceeffe,
  200: #ade4fd,
  300: #8cd9fc,
  400: #73d0fb,
  500: #5ac8fa,
  600: #52c2f9,
  700: #48bbf9,
  800: #3fb4f8,
  900: #2ea7f6,
  A100: #ffffff,
  A200: #ffffff,
  A400: #d1ecff,
  A700: #b8e1ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-black: (
  50: #e7e7e7,
  100: #c2c2c2,
  200: #999999,
  300: #707070,
  400: #525252,
  500: #333333,
  600: #2e2e2e,
  700: #272727,
  800: #202020,
  900: #141414,
  A100: #ef6e6e,
  A200: #ea4040,
  A400: #f60000,
  A700: #dd0000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mat-gray: (
  50: #fafafa,
  100: #f3f3f3,
  200: #ebebeb,
  300: #e2e2e2,
  400: #dcdcdc,
  500: #d6d6d6,
  600: #d1d1d1,
  700: #cccccc,
  800: #c6c6c6,
  900: #bcbcbc,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #fffdfd,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-graylight: (
  50: #fdfdfd,
  100: #fafafa,
  200: #f7f7f7,
  300: #f3f3f3,
  400: #f1f1f1,
  500: #eeeeee,
  600: #ececec,
  700: #e9e9e9,
  800: #e7e7e7,
  900: #e2e2e2,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-green: (
  50: #e0faf1,
  100: #b3f3dc,
  200: #80ebc5,
  300: #4de2ad,
  400: #26dc9c,
  500: #00d68a,
  600: #00d182,
  700: #00cc77,
  800: #00c66d,
  900: #00bc5a,
  A100: #e5fff0,
  A200: #b2ffd2,
  A400: #7fffb4,
  A700: #65ffa5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-navy: (
  50: #e4e4e7,
  100: #bbbbc2,
  200: #8e8e9a,
  300: #606071,
  400: #3e3e52,
  500: #1c1c34,
  600: #19192f,
  700: #141427,
  800: #111121,
  900: #090915,
  A100: #5858ff,
  A200: #2525ff,
  A400: #0000f1,
  A700: #0000d7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-cream: (
  50: #fefdfc,
  100: #fcfbf9,
  200: #faf8f5,
  300: #f8f5f0,
  400: #f7f2ed,
  500: #f5f0ea,
  600: #f4eee7,
  700: #f2ece4,
  800: #f0e9e1,
  900: #eee5db,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-teal: (
  50: #e3f1ef,
  100: #b9dbd8,
  200: #8bc3be,
  300: #5daba4,
  400: #3a9991,
  500: #17877d,
  600: #147f75,
  700: #11746a,
  800: #0d6a60,
  900: #07574d,
  A100: #8bffee,
  A200: #58ffe7,
  A400: #25ffdf,
  A700: #0bffdc,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-darkgrey: (
  50: #f1f1f3,
  100: #dddde0,
  200: #c6c6cc,
  300: #afafb8,
  400: #9e9ea8,
  500: #8d8d99,
  600: #858591,
  700: #7a7a86,
  800: #70707c,
  900: #5d5d6b,
  A100: #ddddfd,
  A200: #adadfa,
  A400: #7575ff,
  A700: #5c5cff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-purple: (
  50: #f8f9fc,
  100: #eff1f8,
  200: #e4e8f4,
  300: #d9dff0,
  400: #d0d8ec,
  500: #c8d1e9,
  600: #c2cce6,
  700: #bbc6e3,
  800: #b4c0df,
  900: #a7b5d9,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-green-one: (
  50: #e0faf1,
  100: #b3f3dc,
  200: #80ebc5,
  300: #4de2ad,
  400: #26dc9c,
  500: #00d68a,
  600: #00d182,
  700: #00cc77,
  800: #00c66d,
  900: #00bc5a,
  A100: #e5fff0,
  A200: #b2ffd2,
  A400: #7fffb4,
  A700: #65ffa5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-green-two: (
  50: #e4f6f0,
  100: #bbe8da,
  200: #8dd8c2,
  300: #5fc8a9,
  400: #3dbd96,
  500: #1bb184,
  600: #18aa7c,
  700: #14a171,
  800: #109867,
  900: #088854,
  A100: #b6ffde,
  A200: #83ffc7,
  A400: #50ffb0,
  A700: #36ffa5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-green-three: (
  50: #e3f1ef,
  100: #b9dbd8,
  200: #8bc3be,
  300: #5daba3,
  400: #3a9990,
  500: #17877c,
  600: #147f74,
  700: #117469,
  800: #0d6a5f,
  900: #07574c,
  A100: #8bffec,
  A200: #58ffe4,
  A400: #25ffdc,
  A700: #0bffd8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-green-four: (
  50: #e2e8e8,
  100: #b8c5c5,
  200: #889e9e,
  300: #587777,
  400: #355959,
  500: #113c3c,
  600: #0f3636,
  700: #0c2e2e,
  800: #0a2727,
  900: #051a1a,
  A100: #59ffff,
  A200: #26ffff,
  A400: #00f2f2,
  A700: #00d9d9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-oat-one: (
  50: #fefdfc,
  100: #fcfbf9,
  200: #faf8f5,
  300: #f8f5f0,
  400: #f7f2ed,
  500: #f5f0ea,
  600: #f4eee7,
  700: #f2ece4,
  800: #f0e9e1,
  900: #eee5db,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-oat-two: (
  50: #fcfcfb,
  100: #f8f6f5,
  200: #f3f1ee,
  300: #eeebe7,
  400: #ebe6e1,
  500: #e7e2dc,
  600: #e4dfd8,
  700: #e0dad3,
  800: #ddd6ce,
  900: #d7cfc5,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-slate: (
  50: #e4e4e6,
  100: #babac2,
  200: #8d8d99,
  300: #5f5f70,
  400: #3c3c51,
  500: #1a1a32,
  600: #17172d,
  700: #131326,
  800: #0f0f1f,
  900: #080813,
  A100: #5656ff,
  A200: #2323ff,
  A400: #0000ef,
  A700: #0000d5,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-straw: (
  50: #fafaf2,
  100: #f2f2de,
  200: #e9e9c9,
  300: #e0e0b3,
  400: #d9daa2,
  500: #d2d392,
  600: #cdce8a,
  700: #c7c87f,
  800: #c1c275,
  900: #b6b763,
  A100: #ffffff,
  A200: #fffff4,
  A400: #feffc1,
  A700: #fdffa7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-coral: (
  50: #fdf0ec,
  100: #f9dace,
  200: #f6c2ae,
  300: #f2a98e,
  400: #ef9675,
  500: #ec845d,
  600: #ea7c55,
  700: #e7714b,
  800: #e46741,
  900: #df5430,
  A100: #ffffff,
  A200: #fff2ef,
  A400: #ffc9bc,
  A700: #ffb4a2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-mint: (
  50: #f5f9f8,
  100: #e5f0ee,
  200: #d4e7e2,
  300: #c2ddd6,
  400: #b5d5ce,
  500: #a8cec5,
  600: #a0c9bf,
  700: #97c2b8,
  800: #8dbcb0,
  900: #7db0a3,
  A100: #ffffff,
  A200: #ffffff,
  A400: #d1fff4,
  A700: #b8ffed,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-sky: (
  50: #f2f4fa,
  100: #dfe4f2,
  200: #c9d2ea,
  300: #b3c0e2,
  400: #a3b3db,
  500: #93a5d5,
  600: #8b9dd0,
  700: #8093ca,
  800: #768ac4,
  900: #6479ba,
  A100: #ffffff,
  A200: #f7f9ff,
  A400: #c4d2ff,
  A700: #abbfff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-blue-one: (
  50: #f5f5ff,
  100: #e6e7fe,
  200: #d5d7fe,
  300: #c4c6fe,
  400: #b8bafd,
  500: #abaefd,
  600: #a4a7fd,
  700: #9a9dfc,
  800: #9194fc,
  900: #8084fc,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-blue-two: (
  50: #f0f1ff,
  100: #dadcfe,
  200: #c2c4fe,
  300: #a9acfe,
  400: #969bfd,
  500: #8489fd,
  600: #7c81fd,
  700: #7176fc,
  800: #676cfc,
  900: #5459fc,
  A100: #ffffff,
  A200: #ffffff,
  A400: #f6f6ff,
  A700: #dcddff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-blue-three: (
  50: #eaebff,
  100: #cbcdfe,
  200: #a8abfd,
  300: #8589fc,
  400: #6a70fc,
  500: #5057fb,
  600: #494ffa,
  700: #4046fa,
  800: #373cf9,
  900: #272cf8,
  A100: #ffffff,
  A200: #ffffff,
  A400: #cecfff,
  A700: #b4b6ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-blue-four: (
  50: #e6e7ff,
  100: #c1c4ff,
  200: #989dff,
  300: #6e75ff,
  400: #4f58ff,
  500: #303aff,
  600: #2b34ff,
  700: #242cff,
  800: #1e25ff,
  900: #1318ff,
  A100: #ffffff,
  A200: #fafaff,
  A400: #c7c8ff,
  A700: #adafff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-blue-five: (
  50: #e4e5fa,
  100: #bbbef2,
  200: #8e93e9,
  300: #6067e0,
  400: #3e47d9,
  500: #1c26d2,
  600: #1922cd,
  700: #141cc7,
  800: #1117c1,
  900: #090db6,
  A100: #e1e2ff,
  A200: #aeafff,
  A400: #7b7dff,
  A700: #6264ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-blue-six: (
  50: #e1e3f5,
  100: #b5b8e6,
  200: #8489d5,
  300: #5259c4,
  400: #2d36b7,
  500: #0812aa,
  600: #0710a3,
  700: #060d99,
  800: #040a90,
  900: #02057f,
  A100: #adadff,
  A200: #7a7bff,
  A400: #4748ff,
  A700: #2d2fff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-blue-seven: (
  50: #e3e0eb,
  100: #b9b3ce,
  200: #8b80ae,
  300: #5c4d8d,
  400: #392674,
  500: #16005c,
  600: #130054,
  700: #10004a,
  800: #0c0041,
  900: #060030,
  A100: #6e69ff,
  A200: #3d36ff,
  A400: #0c03ff,
  A700: #0800e8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
